import request from '@/utils/request';
//zml消息列表（分页）
export function ApiLog(data) {
  return request({
    url: '/clientserver.php/ApiLog/index',
    method: 'post',
    data: data
  });
}
//api操作日志
export function ApiOperation(data) {
  return request({
    url: '/clientserver.php/BackstageOperation/api_log_list',
    method: 'post',
    data: data
  });
}
//学员来源修改
export function updateSource(data) {
  return request({
    url: '/clientserver.php/BackstageOperation/update_customer_source',
    method: 'post',
    data: data
  });
}
//业务日志查询
export function businessLog(data) {
  return request({
    url: '/clientserver.php/BackstageOperation/log_dir',
    method: 'post',
    data: data
  });
}
//业务日志下载
export function logDownload(data) {
  return request({
    url: '/clientserver.php/BackstageOperation/log_dir_download',
    method: 'post',
    data: data
  });
}
//业务日志预览
export function logView(data) {
  return request({
    url: '/clientserver.php/BackstageOperation/log_file_read',
    method: 'post',
    data: data
  });
}
//数据库查询
export function implement(data) {
  return request({
    url: '/clientserver.php/BackstageOperation/implement',
    method: 'post',
    data: data
  });
}
export function GetMakeTypeList(data) {
  return request({
    url: '/clientserver.php/Common/getMakeTypeList',
    method: 'post',
    data: data
  });
}